.container {
  background-color: #f8f7f4;
  padding: 0;
  color: #231f20;
  width: 100%;

  & .footerText {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    font-family: Montserrat, sans-serif;
    color: #231f20;
    letter-spacing: normal;

    & a {
      color: #9c0c19;
    }
  }
 
  & .femexcelLogoContainer,
  .maleexcelLogoContainer {
    margin-bottom: 40px;
  }
}

  .femexcelLogo,
  .maleexcelLogo {
    padding-bottom: 20px;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-image-outset: 0;
    border-image-repeat: stretch;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgb(95, 200, 240) 50%, rgba(0, 0, 0, 0) 100%);
    border-image-width: 1;
    border-left-style: solid;
    border-left-width: 0px;
    border-right-style: solid;
    border-right-width: 0px;
    border-top-style: solid;
    border-top-width: 0px;
  }

.boxContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #231f20;
}

.hr {
  border-style: inset;
  background-color: #231f20; 
}

.box {
  color: white;
  flex-direction: column;

  & .footerTitle {
    font-size: 1rem;
    font-weight: 600;
    padding: 10px 0;
    font-family: Poppins, sans-serif;
    color: #231f20;
    text-transform: uppercase;
    letter-spacing: normal;

    &.socialText {
      font-size: 1.1rem;
      padding: 30px 0;
    }
  }

  & .hours,
  .phone,
  .sms,
  .email {
    font-family: Montserrat, sans-serif;
    color: #231f20;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: normal;
  }

  & a {
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    line-height: 24px;
    color: #231f20;
    text-decoration: none;
    display: flex;

    /* &:not(.hours):hover {
      color: #9c0c19;
    } */
  }
}

.logos {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 229px;
}

.logo {
  padding-bottom: 10px;
  border-bottom: 1px solid white;
}

.social {
  max-width: 60%;

  & .socialText {
    font-size: 1.1rem;
    font-weight: bold;
    padding: 30px 0;
  }

  & .socialIcons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px 0;

    & .socialIcon {
      width: 25px;
      height: 25px;
      fill: #231f20;
    }
  }
}

.topRow {
  background-color: #e8eef3;
  width: 100%;
}

.bottomRow {
  padding-top: 10px;
  justify-content: space-between;
}

.femaleBottomRow {
  padding-top: 40px;
  padding-bottom: 10px;
  justify-content: center;
}

.maleLinks {
  & a:hover {
    color: #9c0c19;
  }
}

.femLinks {
  a:hover {
    color: rgb(1, 173, 171) !important;
  }
}
