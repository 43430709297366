@tailwind base;
@tailwind components;
@tailwind utilities;

/* !IMPORTANT NOTICE!
  This particular code is necessary for Tailwind and MUI to coexist.
  Tailwind will override these values by default, and that breaks some
  existing styling. To set it back to normal, line was added.
  This line should be removed when MUI is removed from the project.

  - Nanda (2022-02-16)
*/
*, ::before, ::after { box-sizing: initial }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5fBBc4AMP6lQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Roboto';
  font-display: swap;
  font-style: normal;
  font-weight: 900;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmYUtfBBc4AMP6lQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
  /* These two properties are a failsafe to make sure PageScrollControl doesn't mess up. */
  overflow: auto;
  padding-right: 0;

  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Scrollbar styling */
/* width */
::-webkit-scrollbar {
  width: 6px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #9a9a9a;
  border-radius: 3px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
input[type='number'] {
  -moz-appearance: textfield;
}

#root {
  height: 100vh;
}

.form-radio-black-dot:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox=%270 0 16 16%27 fill=%27%23000000%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3ccircle cx=%278%27 cy=%278%27 r=%275%27/%3e%3c/svg%3e");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.form-radio-red-dot:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox=%270 0 16 16%27 fill=%27%23F75A5A%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3ccircle cx=%278%27 cy=%278%27 r=%275%27/%3e%3c/svg%3e");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.layout-bg-male-order-confirmation {
  background-image: url('assets/waiting-to-purchase/top-image.png'), linear-gradient(rgb(104 157 198 / 58%), rgb(104 157 198 / 58%)), url('assets/waiting-to-purchase/bottom-image.png');
  background-size: 80vh, cover, cover;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: right;
}

.layout-bg-male-order-confirmation-small {
  background-image: linear-gradient(#1f6ca39b, #1f6ca39b), url('assets/waiting-to-purchase/top-image.png'), url('assets/waiting-to-purchase/bottom-image.png');
  background-size: cover, 29.688rem 52.813rem, cover;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: right, 100% 8%;
}



.layout-bg-male-main {
  background-image: url('assets/e_symbol-202203.svg'), linear-gradient(#C9D3DA 1000px, white 1000px);
  background-repeat: no-repeat;
  background-position: left calc(50% + 380px) top 771px, left top;
  background-size: 339px, 100%;
}

.layout-bg-male-main-small {
  background-image: linear-gradient(#C9D3DA 500px, white 500px);
  background-repeat: no-repeat;
}

.layout-bg-all-blue {
      background-image: url('assets/e_symbol.svg');
      background-repeat: no-repeat;
      background-position: right 10% top 190px;
      background-color: #C9D3DA;
}

.layout-bg-all-blue-small {
     background-color: #C9D3DA;
}

@layer components {
  .scarlet-focus-outline {
    @apply outline-none focus-within:outline-2 focus-within:outline-scarlet;
  }
  .paper-default {
    @apply box-border bg-stone-100 p-4 md:p-8 drop-shadow md:drop-shadow;
  }

  .paper-bottom-card-fem {
    @apply box-border bg-bottom-card-gray-female p-4 md:p-8 drop-shadow md:drop-shadow;
  }


}

.MuiIconButton-label > input[type="checkbox"]:not(:checked) + .MuiSvgIcon-root {
  color: #141414;
}
.fem-spinner-color-femexcel-dark {
  color: hsl(284, 22%, 45%);
}

.grecaptcha-badge {
  visibility: hidden;
}